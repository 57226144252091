/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import { VALIDATION_MESSAGE } from '@/common/validation';
import {
  ReachSimulatorConditionsForResult,
  RsmProjectResult,
  RsmProjectResultReachCurves,
  ReachSimulatorConditionsForResultGoalTypeEnum
} from '@/api/openapi';
import { round, roundNumber } from '@/common/formatter';
import { useStationColor } from '@/composables/campaign/brand_lift/colors';
import { CHART_OPTIONS_BASE } from '@/composables/planning/rsm/simulationResult';
import { Ref, computed, ref } from 'vue';

export const STATIONS_CHART_OPTIONS = {
  ...CHART_OPTIONS_BASE,
  chart: {
    ...CHART_OPTIONS_BASE.chart,
    height: '440px'
  },
  yAxis: {
    ...CHART_OPTIONS_BASE.yAxis,
    gridLineDashStyle: 'Dash',
    gridLineWidth: 1,
    labels: {
      ...CHART_OPTIONS_BASE.yAxis.labels,
      formatter: function(label) {
        return label.value;
      }
    },
    opposite: false
  },
  xAxis: {
    tickLength: 0,
    labels: {
      style: {
        fontSize: '10px',
        lineHeight: 1.3,
        color: '#42525299'
      }
    }
  }
};

export const REACH_CURVES_TYPE = {
  numOfEffectiveReach: {
    label: 'エフェクティブリーチ人数',
    id: 'numOfEffectiveReach',
    unit: '人'
  },
  effectiveReachRate: {
    label: 'エフェクティブリーチ率',
    id: 'effectiveReachRate',
    unit: '%'
  }
};

export const REACH_CURVES_RADIO_TYPE: { label: string; value: string }[] = [
  REACH_CURVES_TYPE.numOfEffectiveReach,
  REACH_CURVES_TYPE.effectiveReachRate
].map(v => ({
  label: v.label,
  value: v.id
}));

export const CSV_TYPE = { er: 'ER', fq: 'FQ' } as const;
export type csvType = typeof CSV_TYPE.er | typeof CSV_TYPE.fq;

const EFFECTIVE_REACH_Y_TITLE = {
  [REACH_CURVES_TYPE.numOfEffectiveReach.id]: 'Eリーチ人数(万人)',
  [REACH_CURVES_TYPE.effectiveReachRate.id]: 'Eリーチ率(%)'
};

type reachCurvesReturnTypes = {
  activeRadio: Ref<string>;
  chartOptions: Ref<unknown>;
  downloadCsv: (
    result: RsmProjectResult,
    fileName: string,
    type: csvType
  ) => void;
};

const resolveNum = (value: number): number => {
  if (typeof value != 'number') return value;
  if (Math.abs(value) >= 10_000) {
    return round(value / 10_000, 1);
  }
  return value / 10_000;
};

const useRadios = ({
  radios
}: {
  radios: { value: string; label: string }[];
}) => {
  const active = ref(radios[0].value);
  return {
    active
  };
};

export const useSimulationResultReachCurves = (
  reachCurves: RsmProjectResultReachCurves[]
): reachCurvesReturnTypes => {
  const { getStationColorByCode } = useStationColor();

  const { active: activeRadio } = useRadios({
    radios: REACH_CURVES_RADIO_TYPE
  });

  const seriesData = reachCurves.map(v => {
    const reachNumberList = v.reachCurves.map(r => ({
      x: round(r.grp, 2),
      y: resolveNum(r.reachNumber),
      color: '',
      marker: {}
    }));
    reachNumberList.unshift({
      x: 0,
      y: 0,
      color: 'transparent',
      marker: {
        fillColor: 'transparent',
        lineWidth: 0,
        lineColor: 'transparent'
      }
    });
    const reachRateList = v.reachCurves.map(r => ({
      x: round(r.grp, 2),
      y: r.reachRate,
      color: '',
      marker: {}
    }));
    reachRateList.unshift({
      x: 0,
      y: 0,
      color: 'transparent',
      marker: {
        fillColor: 'transparent',
        lineWidth: 0,
        lineColor: 'transparent'
      }
    });
    return {
      label: v.stationName,
      id: v.stationCode,
      color: getStationColorByCode(v.stationCode),
      data: {
        [REACH_CURVES_TYPE.numOfEffectiveReach.id]: reachNumberList,
        [REACH_CURVES_TYPE.effectiveReachRate.id]: reachRateList
      }
    };
  });

  const series = computed(() => {
    return seriesData.map(v => ({
      ...v,
      name: v.label,
      data: v.data[activeRadio.value],
      type: 'line',
      marker: {
        enabled: false,
        symbol: 'circle'
      }
    }));
  });

  let maxGrp = 0;
  reachCurves.map(v => {
    v.reachCurves.map(r => {
      if (maxGrp < r.grp) maxGrp = r.grp;
    });
  });

  const yAxis = computed(() => {
    return [
      {
        ...CHART_OPTIONS_BASE.yAxis,
        title: {
          ...CHART_OPTIONS_BASE.yAxis.title,
          text: EFFECTIVE_REACH_Y_TITLE[activeRadio.value]
        },
        tickAmount: 4,
        labels: {
          ...CHART_OPTIONS_BASE.yAxis.labels,
          formatter: function(label) {
            return label.value.toLocaleString();
          }
        }
      }
    ];
  });

  const xAxis = computed(() => {
    return [
      {
        ...STATIONS_CHART_OPTIONS.xAxis,
        minPadding: 0,
        min: 0,
        title: {
          text: 'GRP',
          align: 'high',
          style: {
            fontSize: '10px',
            lineHeight: 1.3,
            color: '#42525299'
          }
        },
        labels: {
          ...STATIONS_CHART_OPTIONS.xAxis.labels,
          formatter: function(label) {
            return label.value.toLocaleString();
          }
        }
      }
    ];
  });

  const tooltip = computed(() => {
    const stationNames = reachCurves.map(v => v.stationName);
    const unit =
      activeRadio.value === REACH_CURVES_TYPE.numOfEffectiveReach.id
        ? '万人'
        : '%';
    return {
      split: true,
      hideDelay: 100,
      formatter: function(this: Highcharts.TooltipFormatterContextObject) {
        if (this.x === 0) {
          return false;
        }
        const labels = this.points
          ?.filter(v => stationNames.includes(v.series.name))
          ?.map(v => {
            const value =
              activeRadio.value === REACH_CURVES_TYPE.numOfEffectiveReach.id
                ? v.y.toLocaleString()
                : roundNumber(v.y, 2);
            return `${v.series.name}: ${value}${unit}`;
          });
        labels?.unshift('GRP:' + roundNumber(this.x, 1));
        return labels;
      }
    };
  });

  const chartOptions = ref({
    ...STATIONS_CHART_OPTIONS,
    series,
    xAxis,
    yAxis,
    tooltip
  });

  const downloadCsv = (
    result: RsmProjectResult,
    fileName: string,
    type: csvType
  ): void => {
    const content = parseTable(result, type);
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, content], { type: 'text/csv' });
    const link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.setAttribute('href', window.webkitURL.createObjectURL(blob));
    link.click();
  };

  return {
    activeRadio,
    chartOptions,
    downloadCsv
  };
};

/**
 * CSV形式のテーブルデータを生成する
 * @param result リーチシミュレーターの結果系CSVに表示する条件
 * @param type   CSVのデータタイプ(局別リーチまたはフリークエンシー分布)
 */
const parseTable = (result: RsmProjectResult, type: csvType): string => {
  const sub = type === CSV_TYPE.er ? '局別リーチ' : 'フリークエンシー分布';
  const title = 'TVAL - ' + sub + ' シミュレーション';
  const headers = createCsvHeader(result.conditions);
  const bodies =
    type === CSV_TYPE.er ? createCsvBodyER(result) : createCsvBodyFQ(result);

  const lineFeed = '\r\n';
  return (
    title +
    lineFeed +
    headers.join(lineFeed) +
    // ヘッダー部とデータ部の間に空行を入れる
    lineFeed.repeat(3) +
    bodies.join(lineFeed)
  );
};

/**
 * CSVのヘッダー部分を作成する
 * @param conditions リーチシミュレーターの結果系CSVに表示する条件
 */
const createCsvHeader = (
  conditions: ReachSimulatorConditionsForResult
): string[] => {
  const header: Map<string, string | number> = new Map();
  const simulationMode =
    conditions.goalType === ReachSimulatorConditionsForResultGoalTypeEnum.Grp
      ? 'GRP'
      : '予算';
  header.set('シミュレーションモード', simulationMode);
  header.set('エリア', conditions.area);
  header.set('ターゲット', `"${conditions.target}"` ?? '');
  header.set('週数', conditions.numOfWeek);
  header.set('参照期間', conditions.period);
  header.set('合計GRP', `"${Math.round(conditions.grp).toLocaleString()}"`);
  header.set('週別GRP', conditions.grpWeeklySettings);
  header.set('局別パーコスト', `"${conditions.stationPerCostSettings}"`);
  header.set(simulationMode + '比率', conditions.stationRateSettings);
  header.set('有効接触回数', conditions.numOfEffectiveContacts);
  header.set('レポート作成日時', conditions.createdAt);
  header.set('データ提供元', '"Switch Media, Inc."');

  const headers = new Array<string>();
  header.forEach((value, key) => {
    headers.push(`${key}:,${value}`);
  });
  return headers;
};

/**
 * 局別エフェクティブリーチのCSVのデータ(ボディ部分)を作成する
 * @param result リーチシミュレーターの結果
 */
const createCsvBodyER = (result: RsmProjectResult): string[] => {
  const table = new Array<Array<string | number>>();
  const header = [
    '放送局',
    'GRP',
    'エフェクティブリーチ人数（人）',
    'エフェクティブリーチ率（％）'
  ];
  table.push(header);

  const datas: (string | number)[][] = [];
  result.reachCurves.map(it => {
    it.reachCurves.map(child => {
      datas.push([
        // 放送局
        it.stationName,
        // GRP
        round(child.grp, 2),
        // エフェクティブリーチ人数（人）
        round(child.reachNumber, 1),
        // エフェクティブリーチ率（％）
        roundNumber(child.reachRate, 7)
      ]);
    });
  });

  table.push(...datas);
  const bodies = new Array<string>();
  table.forEach(it => {
    bodies.push(it.join(','));
  });
  return bodies;
};

/**
 * 局別フリークエンシー分布のCSVのデータ(ボディ部分)を作成する
 * @param result リーチシミュレーターの結果
 */
const createCsvBodyFQ = (result: RsmProjectResult): string[] => {
  const table = new Array<Array<string | number>>();
  const maxTimes = 20;
  const times = [...Array(maxTimes)].map((_, i) => i + '回');
  times.push(maxTimes + '回以上');
  const header = ['放送局', '平均フリークエンシー'].concat(times);
  table.push(header);

  const datas: (string | number)[][] = [];
  result.frequencies.map(it => {
    const d = [
      // 放送局
      it.stationName,
      // 平均フリークエンシー
      round(it.averageFrequency, 7)
    ];
    // フリークエンシー(比率)
    const fractionList: number[] = [];
    it.frequencies.map(f => {
      if (f.frequency <= maxTimes) {
        fractionList.push(round(f.fraction, 7));
      } else {
        const last = fractionList.pop() ?? 0;
        fractionList.push(round(f.fraction + last, 7));
      }
    });
    datas.push(d.concat(fractionList));
  });

  table.push(...datas);
  const bodies = new Array<string>();
  table.forEach(it => {
    bodies.push(it.join(','));
  });
  return bodies;
};
