/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ReachSimulatorConditionsForResult,
  RsmProjectResult,
  RsmProjectResultEffectiveReach,
  ReachSimulatorConditionsForResultGoalTypeEnum
} from '@/api/openapi';
import { round, roundNumber } from '@/common/formatter';
import { CHART_OPTIONS_BASE } from '@/composables/planning/rsm/simulationResult';
import { Ref, computed, ref } from 'vue';

export const EREACH_CHART_OPTIONS = {
  ...CHART_OPTIONS_BASE,
  chart: {
    ...CHART_OPTIONS_BASE.chart
  },
  yAxis: {
    ...CHART_OPTIONS_BASE.yAxis,
    gridLineDashStyle: 'Dash',
    gridLineWidth: 1,
    labels: {
      ...CHART_OPTIONS_BASE.yAxis.labels,
      formatter: function(label) {
        return label.value;
      }
    },
    opposite: false
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '10px',
        lineHeight: 1.3,
        color: '#42525299'
      }
    }
  }
};

export const EFFECTIVE_REACH_TYPE = {
  numOfEffectiveReach: {
    label: 'エフェクティブリーチ人数',
    id: 'numOfEffectiveReach',
    unit: '人'
  },
  effectiveReachRate: {
    label: 'エフェクティブリーチ率',
    id: 'effectiveReachRate',
    unit: '%'
  }
};

export const EFFECTIVE_REACH_RADIO_TYPE: { label: string; value: string }[] = [
  EFFECTIVE_REACH_TYPE.numOfEffectiveReach,
  EFFECTIVE_REACH_TYPE.effectiveReachRate
].map(v => ({
  label: v.label,
  value: v.id
}));

const EFFECTIVE_REACH_DATA_TYPE = {
  reachAccumulatedNumber: {
    label: 'エフェクティブリーチ人数',
    id: 'reachAccumulatedNumber',
    color: '#6B90DF',
    type: 'line',
    marker: {
      symbol: 'circle',
      lineWidth: 1
    }
  },
  // エフェクティブリーチ人数の最終リーチ人数表示用のコピーデータ
  reachAccumulatedNumberCopy: {
    label: '最終リーチ人数',
    id: 'reachAccumulatedNumberCopy',
    color: '#F39CBC',
    type: 'line',
    marker: {
      symbol: 'circle',
      fillColor: '#F39CBC',
      lineWidth: 1
    }
  },
  reachWeeklyNumber: {
    label: '週別Eリーチ人数',
    id: 'reachWeeklyNumber',
    color: '#E4EBFA',
    type: 'column',
    pointWidth: 26,
    marker: {},
    states: {
      hover: {
        color: '#E4EBFA73'
      },
      inactive: {
        enabled: true,
        opacity: 0.45
      }
    }
  },
  reachAccumulatedRate: {
    label: 'エフェクティブリーチ率',
    id: 'reachAccumulatedRate',
    color: '#6B90DF',
    type: 'line',
    marker: {
      symbol: 'circle',
      lineWidth: 1
    }
  },
  // エフェクティブリーチ率の最終リーチ率表示用のコピーデータ
  reachAccumulatedRateCopy: {
    label: '最終リーチ率',
    id: 'reachAccumulatedRateCopy',
    color: '#F39CBC',
    type: 'line',
    marker: {
      symbol: 'circle',
      fillColor: '#F39CBC',
      lineWidth: 1
    }
  },
  reachWeeklyRate: {
    label: '週別Eリーチ率',
    id: 'reachWeeklyRate',
    color: '#E4EBFA',
    type: 'column',
    pointWidth: 26,
    marker: {},
    states: {
      hover: {
        color: '#E4EBFA73'
      },
      inactive: {
        enabled: true,
        opacity: 0.45
      }
    }
  }
};

const EFFECTIVE_REACH_SERIES_TYPE = {
  [EFFECTIVE_REACH_TYPE.numOfEffectiveReach.id]: [
    EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedNumber,
    EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedNumberCopy,
    EFFECTIVE_REACH_DATA_TYPE.reachWeeklyNumber
  ],
  [EFFECTIVE_REACH_TYPE.effectiveReachRate.id]: [
    EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedRate,
    EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedRateCopy,
    EFFECTIVE_REACH_DATA_TYPE.reachWeeklyRate
  ]
};

const EFFECTIVE_REACH_Y_TITLE = {
  [EFFECTIVE_REACH_TYPE.numOfEffectiveReach.id]:
    'エフェクティブリーチ人数(万人)',
  [EFFECTIVE_REACH_TYPE.effectiveReachRate.id]: 'エフェクティブリーチ率(%)'
};

type effectiveReachReturnTypes = {
  activeRadio: Ref<string>;
  chartOptions: Ref<unknown>;
  downloadCsv: (result: RsmProjectResult, fileName: string) => void;
};

const resolveNum = (value: number): number => {
  if (Math.abs(value) >= 10_000) {
    return round(value / 10_000, 1);
  }
  return value / 10_000;
};

const useRadios = ({
  radios
}: {
  radios: { value: string; label: string }[];
}) => {
  const active = ref(radios[0].value);
  return {
    active
  };
};

const setBefLastData = (val: number) => {
  return {
    y: val,
    marker: {
      symbol: 'circle',
      fillColor: '#6B90DF',
      lineColor: '#6B90DF',
      lineWidth: 3
    }
  };
};

export const useSimulationResultEffectiveReach = (
  eReach: RsmProjectResultEffectiveReach[]
): effectiveReachReturnTypes => {
  const { active: activeRadio } = useRadios({
    radios: EFFECTIVE_REACH_RADIO_TYPE
  });

  const seriesData = {
    [EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedNumber.id]: eReach.map(
      (v, i) => {
        if (i !== eReach.length - 1) {
          return resolveNum(v.reachAccumulatedNumber);
        } else {
          return null; // 最終週は値無しにする
        }
      }
    ),
    // 最終リーチ表示用
    [EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedNumberCopy.id]: eReach.map(
      (v, i) => {
        if (i === eReach.length - 2) {
          return setBefLastData(resolveNum(v.reachAccumulatedNumber));
        } else if (i === eReach.length - 1) {
          return resolveNum(v.reachAccumulatedNumber);
        } else {
          return null;
        }
      }
    ),
    [EFFECTIVE_REACH_DATA_TYPE.reachWeeklyNumber.id]: eReach.map(v =>
      resolveNum(v.reachWeeklyNumber)
    ),
    [EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedRate.id]: eReach.map((v, i) => {
      if (i !== eReach.length - 1) {
        return v.reachAccumulatedRate;
      } else {
        return null; // 最終週は値無しにする
      }
    }),
    // 最終リーチ表示用
    [EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedRateCopy.id]: eReach.map(
      (v, i) => {
        if (i === eReach.length - 2) {
          return setBefLastData(v.reachAccumulatedRate);
        } else if (i === eReach.length - 1) {
          return v.reachAccumulatedRate;
        } else {
          return null;
        }
      }
    ),
    [EFFECTIVE_REACH_DATA_TYPE.reachWeeklyRate.id]: eReach.map(
      v => v.reachWeeklyRate
    )
  };

  const maxAccumulatedValue = computed(() => {
    if (activeRadio.value === EFFECTIVE_REACH_TYPE.numOfEffectiveReach.id) {
      const arr = eReach.map(v => resolveNum(v.reachAccumulatedNumber));
      return Math.max(...arr);
    } else {
      const arr = eReach.map(v => v.reachAccumulatedRate);
      return Math.max(...arr);
    }
  });

  // 現在表示している線グラフのデータ
  const currentLineData = computed(() => {
    if (activeRadio.value === EFFECTIVE_REACH_TYPE.numOfEffectiveReach.id) {
      return eReach.map(v => resolveNum(v.reachAccumulatedNumber));
    } else {
      return eReach.map(v => v.reachAccumulatedRate);
    }
  });

  const series = computed(() => {
    // 週数が1週の場合、グラフに「エフェクティブリーチ人数」「エフェクティブリーチ率」を表示しない
    const currentSeriesType =
      eReach.length === 1
        ? EFFECTIVE_REACH_SERIES_TYPE[activeRadio.value].filter(
            v =>
              v.id !== 'reachAccumulatedNumber' &&
              v.id !== 'reachAccumulatedRate'
          )
        : EFFECTIVE_REACH_SERIES_TYPE[activeRadio.value];
    return currentSeriesType.map((v, i) => ({
      ...v,
      name: v.label,
      data: seriesData[v.id],
      marker: {
        ...v.marker,
        lineColor: v.color
      },
      onSeries: 'dataseries',
      zIndex: currentSeriesType.length + 1 - i,
      zoneAxis: 'x',
      zones: [
        {
          value: seriesData[v.id].length,
          color:
            v.id !== EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedNumberCopy.id &&
            v.id !== EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedRateCopy.id
              ? v.color
              : '#6B90DF'
        }
      ]
    }));
  });

  const numberList: number[] = [];
  eReach.map(v => {
    numberList.push(resolveNum(v.reachAccumulatedNumber));
    numberList.push(resolveNum(v.reachWeeklyNumber));
  });
  const rateList: number[] = [];
  eReach.map(v => {
    rateList.push(v.reachAccumulatedRate);
    rateList.push(v.reachWeeklyRate);
  });
  const yMax = {
    [EFFECTIVE_REACH_TYPE.numOfEffectiveReach.id]: Math.max(...numberList),
    [EFFECTIVE_REACH_TYPE.effectiveReachRate.id]: Math.max(...rateList)
  };

  const yAxis = computed(() => {
    return [
      {
        ...CHART_OPTIONS_BASE.yAxis,
        title: {
          ...CHART_OPTIONS_BASE.yAxis.title,
          text: EFFECTIVE_REACH_Y_TITLE[activeRadio.value]
        },
        plotLines: [
          {
            value: maxAccumulatedValue.value,
            width: 1,
            color: '#425252CC',
            acrossPanes: false,
            dashStyle: 'dash'
          }
        ],
        tickAmount: 6,
        max: yMax[activeRadio.value],
        labels: {
          ...CHART_OPTIONS_BASE.yAxis.labels,
          formatter: function(label) {
            return label.value.toLocaleString();
          }
        }
      }
    ];
  });

  const xAxis = computed(() => {
    const grpAccumulatedList = eReach.map(v =>
      v.grpAccumulated.toLocaleString()
    );
    const grpWeeklyList = eReach.map(
      v => '(' + v.grpWeekly.toLocaleString() + ')'
    );
    const weekNumberList = eReach.map(v => v.weekNumber + '週目');
    return [
      {
        ...EREACH_CHART_OPTIONS.xAxis,
        categories: grpAccumulatedList,
        title: {
          text: '累積GRP',
          align: 'high',
          offset: 9,
          widthAdjust: 20,
          verticalAlign: 'top',
          style: {
            fontSize: '10px',
            lineHeight: 1.3,
            color: '#42525299'
          }
        },
        plotLines: currentLineData.value.map((v, i) => {
          return {
            color: '#425252CC',
            width: 1,
            value: i,
            zIndex: currentLineData.value.length + 1,
            dashStyle: 'dash'
          };
        })
      },
      {
        ...EREACH_CHART_OPTIONS.xAxis,
        categories: grpWeeklyList,
        linkedTo: 0,
        lineWidth: 0,
        offset: 15,
        title: {
          text: '週別GRP',
          align: 'high',
          offset: 9,
          style: {
            fontSize: '10px',
            lineHeight: 1.3,
            color: '#42525299'
          }
        }
      },
      {
        ...EREACH_CHART_OPTIONS.xAxis,
        categories: weekNumberList,
        linkedTo: 0,
        lineWidth: 0,
        offset: 30,
        labels: {
          style: {
            ...EREACH_CHART_OPTIONS.xAxis.labels.style,
            color: '#425252'
          }
        },
        title: {
          text: '週数',
          align: 'high',
          offset: 9,
          padding: 20,
          style: {
            fontSize: '10px',
            lineHeight: 1.3,
            color: '#425252',
            padding: 20
          }
        }
      }
    ];
  });

  const tooltip = {
    split: true,
    hideDelay: 100,
    formatter: function(this: Highcharts.TooltipFormatterContextObject) {
      const labels = this.points?.map(v => {
        if (activeRadio.value === EFFECTIVE_REACH_TYPE.numOfEffectiveReach.id) {
          switch (v.series.name) {
            case `${EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedNumber.label}`:
            case `${EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedNumberCopy.label}`:
            case `${EFFECTIVE_REACH_DATA_TYPE.reachWeeklyNumber.label}`:
              return v.y >= 1
                ? `${v.series.name}: ${v.y.toLocaleString() + '万人'}<br>`
                : `${v.series.name}: ${round(v.y * 10_000, 1) + '人'}<br>`;
            default:
              return '';
          }
        } else {
          switch (v.series.name) {
            case `${EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedRate.label}`:
            case `${EFFECTIVE_REACH_DATA_TYPE.reachAccumulatedRateCopy.label}`:
            case `${EFFECTIVE_REACH_DATA_TYPE.reachWeeklyRate.label}`:
              return `${v.series.name}: ${roundNumber(v.y, 2) + '%'}<br>`;
            default:
              return '';
          }
        }
      });
      if (labels?.length === 3) {
        return `${labels.filter(v => !v.includes('最終リーチ')).join('')}`;
      }
      return `${labels?.join('')}`;
    }
  };

  // plotLinesの長さ調整
  // 参考：https://jsfiddle.net/42vy61qr/46/,  https://jsfiddle.net/hfrntt/bL6fm/54
  const setPlotLines = chart => {
    const len = chart?.plotTop;
    const yMax = chart.yAxis[0].max; // Y軸の最大値
    const yHeight = chart.xAxis[0]?.height; // グラフを描画している領域の高さ
    const markerSize = 6; // 線グラフの丸のサイズ
    const isHiddenGrafh = chart.legend.allItems.some(v => v.visible === false);
    chart.xAxis[0]?.plotLinesAndBands?.map((v, i) => {
      const band = v?.svgElem;
      if (!band) return;
      const path = band.d.split(' '); // plotLinesの定義を配列で取得
      const diffValue = yMax - currentLineData.value[i];
      // グラフの画面サイズ、値の比率から設定するplotLinesの長さを算出
      const height = (yHeight * diffValue) / yMax + len + markerSize;
      path[2] = height; // plotLinesの長さを設定
      band.attr('d', path);
      band.attr('opacity', isHiddenGrafh ? 0 : 1);
    });
  };

  const chartOptions = ref({
    ...EREACH_CHART_OPTIONS,
    series,
    xAxis,
    yAxis,
    tooltip,
    chart: {
      ...EREACH_CHART_OPTIONS.chart,
      events: {
        load: function() {
          setPlotLines(this);
        },
        redraw: function() {
          setPlotLines(this);
        }
      }
    }
  });

  const downloadCsv = (result: RsmProjectResult, fileName: string): void => {
    const content = parseTable(result);
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, content], { type: 'text/csv' });
    const link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.setAttribute('href', window.webkitURL.createObjectURL(blob));
    link.click();
  };

  return {
    activeRadio,
    chartOptions,
    downloadCsv
  };
};

/**
 * CSV形式のテーブルデータを生成する
 * @param result リーチシミュレーターの結果系CSVに表示する条件
 */
const parseTable = (result: RsmProjectResult): string => {
  const title = 'TVAL - エフェクティブリーチ シミュレーション';
  const headers = createCsvHeader(result.conditions);
  const bodies = createCsvBody(result);

  const lineFeed = '\r\n';
  return (
    title +
    lineFeed +
    headers.join(lineFeed) +
    // ヘッダー部とデータ部の間に空行を入れる
    lineFeed.repeat(3) +
    bodies.join(lineFeed)
  );
};

/**
 * CSVのヘッダー部分を作成する
 * @param conditions リーチシミュレーターの結果系CSVに表示する条件
 */
const createCsvHeader = (
  conditions: ReachSimulatorConditionsForResult
): string[] => {
  const header: Map<string, string | number> = new Map();
  const simulationMode =
    conditions.goalType === ReachSimulatorConditionsForResultGoalTypeEnum.Grp
      ? 'GRP'
      : '予算';
  header.set('シミュレーションモード', simulationMode);
  header.set('エリア', conditions.area);
  header.set('ターゲット', `"${conditions.target}"` ?? '');
  header.set('週数', conditions.numOfWeek);
  header.set('参照期間', conditions.period);
  header.set('合計GRP', `"${Math.round(conditions.grp).toLocaleString()}"`);
  header.set('週別GRP', conditions.grpWeeklySettings);
  header.set('局別パーコスト', `"${conditions.stationPerCostSettings}"`);
  header.set(simulationMode + '比率', conditions.stationRateSettings);
  header.set('有効接触回数', conditions.numOfEffectiveContacts);
  header.set('レポート作成日時', conditions.createdAt);
  header.set('データ提供元', '"Switch Media, Inc."');

  const headers = new Array<string>();
  header.forEach((value, key) => {
    headers.push(`${key}:,${value}`);
  });
  return headers;
};

/**
 * CSVのデータ(ボディ部分)を作成する
 * @param result リーチシミュレーターの結果
 */
const createCsvBody = (result: RsmProjectResult): string[] => {
  const table = new Array<Array<string | number>>();
  const header = [
    '週数',
    '週別GRP',
    '週別エフェクティブリーチ人数（人）',
    '週別エフェクティブリーチ率（％）',
    '累積GRP',
    '累積エフェクティブリーチ人数（人）',
    '累積エフェクティブリーチ率（％）'
  ];
  table.push(header);

  const datas = result.effectiveReachs.map(it => [
    // 週数
    it.weekNumber + '週目',
    // 週別GRP
    round(it.grpWeekly, 1),
    // 週別エフェクティブリーチ人数（人）
    round(it.reachWeeklyNumber, 1),
    // 週別エフェクティブリーチ率（％）
    round(it.reachWeeklyRate, 7),
    // 累積GRP
    round(it.grpAccumulated, 1),
    // 累積エフェクティブリーチ人数（人）
    round(it.reachAccumulatedNumber, 1),
    // 累積エフェクティブリーチ率（％）
    round(it.reachAccumulatedRate, 7)
  ]);
  table.push(...datas);
  const bodies = new Array<string>();
  table.forEach(it => {
    bodies.push(it.join(','));
  });
  return bodies;
};
