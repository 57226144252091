/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReachSimulatorApi } from '@/api';
import { RsmProjectResult } from '@/api/openapi';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import { fetchAccountAvatar } from '@/composables/fetchImage';
import useLoading from '@/composables/loading';
import { PLANNING_ROUTES, ROUTE_NAMES } from '@/router';
import Axios from 'axios';
import { Ref, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

type simulationResultReturnTypes = {
  avatar: Ref<string | undefined>;
  fetchResult: (params: any) => Promise<RsmProjectResult | undefined>;
  isFetchingResult: Ref<boolean>;
  goToEdit: () => void;
  goToList: () => void;
};

export const useSimulationResult = (): simulationResultReturnTypes => {
  const router = useRouter();
  const route = useRoute();
  const companyId = route.params.companyId.toString();
  if (!companyId) {
    toast({ title: 'カンパニーが選択されていません', variant: 'error' });
  }
  const projectId = route.params.projectId?.toString();
  const avatar = ref();

  const _fetchResult = async (): Promise<RsmProjectResult | undefined> => {
    try {
      const res = await ReachSimulatorApi.getCmPlanningRsmProjectsProjectIdResult(
        Number(projectId)
      );
      if (200 <= res.status && res.status < 300) {
        avatar.value = await fetchAccountAvatar(
          res.data.authorSaasAccountId.toString()
        );
        return res.data;
      } else {
        toast({
          title: '失敗',
          message: 'シミュレーション結果の取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e, 'シミュレーション結果の取得に失敗しました', '');
      } else if (e.status == 403) {
        toast({
          title: '失敗',
          message:
            'シミュレーション結果の取得に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'シミュレーション結果の取得に失敗しました',
          variant: 'error'
        });
      }
    }
  };

  const [isFetchingResult, fetchResult] = useLoading(_fetchResult);

  const goToEdit = async () => {
    await router.push({
      name: PLANNING_ROUTES.simulationEdit,
      params: { ...route.params, projectId: projectId }
    });
  };

  const goToList = async () => {
    await router.push({
      name: PLANNING_ROUTES.rsm,
      params: { ...route.params }
    });
  };

  return {
    avatar,
    fetchResult,
    isFetchingResult,
    goToEdit,
    goToList
  };
};

export const CHART_OPTIONS_BASE = {
  chart: {
    height: 400,
    animation: true,
    spacingLeft: 0,
    marginTop: 28
  },
  series: {
    borderWidth: 0
  },
  yAxis: {
    title: {
      reserveSpace: false,
      align: 'high',
      offset: 0,
      rotation: 0,
      y: -15,
      x: 0,
      style: {
        'text-anchor': 'start',
        fontSize: 10,
        lineHeight: 1.3,
        color: '#42525299'
      }
    },
    labels: {
      style: {
        fontSize: '10px',
        lineHeight: 1.3,
        color: '#42525299'
      }
    }
  },
  title: false,
  credits: {
    enabled: false
  },
  exporting: {
    enabled: true,
    buttons: false,
    allowHTML: true,
    chartOptions: {
      chart: {
        marginTop: 50,
        spacingLeft: 15
      },
      title: {
        style: {
          fontSize: '12px'
        }
      },
      credits: {
        enabled: true,
        text: '© TVAL Switch Media, Inc.'
      },
      xAxis: {
        max: null
      }
    }
  }
};
