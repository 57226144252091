/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RsmProjectResultFrecuencies } from '@/api/openapi';
import { Ref, computed, ref } from 'vue';

import { roundNumber } from '@/common/formatter';
import { CHART_OPTIONS_BASE } from '@/composables/planning/rsm/simulationResult';

export const STATIONS_CHART_OPTIONS = {
  ...CHART_OPTIONS_BASE,
  chart: {
    ...CHART_OPTIONS_BASE.chart,
    height: '403px'
  },
  yAxis: {
    ...CHART_OPTIONS_BASE.yAxis,
    gridLineDashStyle: 'Dash',
    gridLineWidth: 1,
    labels: {
      formatter: function(label) {
        return label.value;
      }
    },
    opposite: false
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '10px',
        lineHeight: 1.3,
        color: '#42525299'
      }
    }
  }
};

type frequenciesReturnTypes = {
  chartOptions: Ref<unknown>;
  isEditMode: Ref<boolean>;
  firstThresholdsEdit: Ref<string>;
  secondThresholdsEdit: Ref<string>;
  thirdThresholdsEdit: Ref<string>;
  setThresholds: () => void;
};

export const useSimulationResultFrequencies = (
  frequencies: RsmProjectResultFrecuencies[]
): frequenciesReturnTypes => {
  const firstThresholds = ref(3);
  const secondThresholds = ref(6);
  const thirdThresholds = ref(9);
  const firstThresholdsEdit = ref(firstThresholds.value.toString());
  const secondThresholdsEdit = ref(secondThresholds.value.toString());
  const thirdThresholdsEdit = ref(thirdThresholds.value.toString());
  const isEditMode = ref(false);

  const thresholds = computed(() => {
    return [
      { str: 0, end: 0 },
      { str: 1, end: firstThresholds.value },
      { str: firstThresholds.value + 1, end: secondThresholds.value },
      { str: secondThresholds.value + 1, end: thirdThresholds.value },
      { str: thirdThresholds.value + 1, end: thirdThresholds.value + 1 }
    ];
  });

  // 比率のグラフデータ
  const stationsNames = frequencies.map(v => v.stationName);
  const seriesFraction: Ref<any[]> = ref([]);

  const setData = () => {
    thresholds.value.map((th, thIndex) => {
      const dataListFraction: number[] = [];
      stationsNames.map(code => {
        let sumFraction = 0;
        const stastionFq = frequencies.find(fq => fq.stationName === code);
        stastionFq?.frequencies.map(fq => {
          if (thIndex === thresholds.value.length - 1) {
            if (th.str <= fq.frequency) sumFraction += fq.fraction;
          } else {
            if (th.str <= fq.frequency && fq.frequency <= th.end)
              sumFraction += fq.fraction;
          }
        });
        dataListFraction.push(sumFraction);
      });

      let label = '';
      if (thIndex === 0) {
        label = '0回';
      } else if (thIndex !== thresholds.value.length - 1) {
        label = th.str + '〜' + th.end + '回';
      } else {
        label = th.end + '回以上';
      }

      seriesFraction.value.push({
        label: label,
        id: thIndex,
        type: 'column',
        data: dataListFraction
      });
    });
  };

  const setThresholds = () => {
    firstThresholds.value = Number(firstThresholdsEdit.value);
    secondThresholds.value = Number(secondThresholdsEdit.value);
    thirdThresholds.value = Number(thirdThresholdsEdit.value);
    setData();
    isEditMode.value = false;
  };

  // 平均FQのグラフデータ
  const dataListAverageFrequency: number[] = [];
  stationsNames.map(code => {
    const stastionFq = frequencies.find(fq => fq.stationName === code);
    if (stastionFq?.averageFrequency || stastionFq?.averageFrequency === 0)
      dataListAverageFrequency.push(stastionFq.averageFrequency);
  });
  const seriesAverageFrequency: Ref<any[]> = ref([]);
  seriesAverageFrequency.value.push({
    label: '平均FQ',
    id: 'averageFrequency',
    type: 'line',
    color: '#425252',
    data: dataListAverageFrequency,
    marker: {
      symbol: 'circle'
    },
    yAxis: 1
  });

  // 平均FQのY軸の調整用
  const maxAverageFrequency = Math.max(...dataListAverageFrequency) * 1.1;

  const seriesData = computed(() => {
    return seriesFraction.value.concat(seriesAverageFrequency.value);
  });

  const series = computed(() => {
    return seriesData.value.map(v => ({
      ...v,
      name: v.label,
      data: v.data,
      id: v.id,
      states: {
        inactive: {
          opacity: 0.8
        }
      }
    }));
  });

  const yAxis = computed(() => {
    return [
      {
        ...CHART_OPTIONS_BASE.yAxis,
        title: {
          ...CHART_OPTIONS_BASE.yAxis.title,
          text: '(%)'
        },
        tickAmount: 3,
        max: 100
      },
      {
        ...CHART_OPTIONS_BASE.yAxis,
        title: {
          ...CHART_OPTIONS_BASE.yAxis.title,
          text: '平均FQ(回)',
          style: {
            ...CHART_OPTIONS_BASE.yAxis.title.style,
            'text-anchor': 'end'
          }
        },
        tickAmount: 3,
        opposite: true,
        max: maxAverageFrequency
      }
    ];
  });

  const xAxis = computed(() => {
    return [
      {
        ...STATIONS_CHART_OPTIONS.xAxis,
        categories: stationsNames
      }
    ];
  });

  const tooltip = computed(() => {
    return {
      split: false,
      hideDelay: 100,
      formatter: function(this: Highcharts.TooltipFormatterContextObject) {
        if (this.percentage) {
          return roundNumber(this.percentage, 2) + '%';
        } else {
          return roundNumber(this.y, 2) + '回';
        }
      }
    };
  });

  const chartOptions = ref({
    ...STATIONS_CHART_OPTIONS,
    series,
    xAxis,
    yAxis,
    tooltip,
    plotOptions: {
      column: {
        stacking: 'percent'
      }
    },
    colors: [
      'var(--dark-10-hex-color)',
      'var(--red-blue_blue-1)',
      'var(--red-blue_blue-2)',
      'var(--red-blue_blue-4)',
      'var(--chart-blue-1)'
    ],
    legend: { enabled: false }
  });

  // 初期処理
  setData();

  return {
    chartOptions,
    isEditMode,
    firstThresholdsEdit,
    secondThresholdsEdit,
    thirdThresholdsEdit,
    setThresholds
  };
};
